import React from 'react'
import './Heading.css';

const Heading = () => {
  return (
  <>
    <div className='second-page-class1'>
      <span className='stroke-text'>Explore Our</span>
      <span>Programs</span>
      <span className='stroke-text'>To shape you</span>
    </div>
    <div className='second-page-class2'>
      <div><span>Strength Training</span><br /><span>In this program, you are trained to improve your strength through many exercises.</span></div>
      <div><span>Cardio Training</span><br /><span>In this program, you are trained to improve your strength through many exercises.</span></div>
      <div><span>Fat Burning</span><br /><span>In this program, you are trained to improve your strength through many exercises.</span></div>
      <div><span>Health Fitness</span><br /><span>In this program, you are trained to improve your strength through many exercises.</span></div>
    </div>
  </>
  )
}

export default Heading