import React, { useRef } from 'react'
import './App.css';
import emailjs from '@emailjs/browser';
// import image1 from '../assets/image1.png';
// import image2 from '../assets/image2.png';
// import image3 from '../assets/image3.png';
// import image4 from '../assets/image4.png';
const App = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0icthtx', 'template_1dkcrji', form.current, 'W10kSVCLPyqQrsH1x')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div>
      <div className='images'>
        {/* <img src={image1} alt="" className='image1' /> */}
        {/* <img src={image2} alt="" className='image' /> */}
        {/* <img src={image3} alt="" className='image3' /> */}
        {/* <img src={image4} alt="" className='image4' /> */}
      </div>
      <form ref={form} className="email-container" onSubmit={sendEmail}>
        <input type="email" name="user_email" placeholder='Enter your email address'></input>
        <button className='btn'>Join Now</button>
      </form>
    </div>
  )
}

export default App