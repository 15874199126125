import React from 'react'
import Logo from '../assets/logo.png';
import Heart from '../assets/heart.png';
import calories from '../assets/calories.png';
import './Header.css';

const Header = () => {
  return (
    <div className='body'>
      <div className="left-h">
        <div className='div-1'>
          <img src={Logo} alt="" className='logo' />
          <ul className='Header-menu'>
            <li>Home</li>
            <li>Programs</li>
            <li>Why Us</li>
            <li>Plans</li>
            <li>Testimonials</li>
          </ul>
        </div>
        <div className='second-heading'>
          <div></div>
          <span>The best fitness club in the Town</span>
        </div>
        <div className="Heading">
          <div><span className='stroke-text'>SHAPE </span><span>Your </span></div>
          <div><span>Ideal Body</span></div><br />
          <div><span>In here we will help you to shape and build Your ideal body and live up your life to fullest</span></div>
        </div>
        <br></br>
        <div className="figure">
          <div><span>+140</span><span>Expert coaches</span></div>
          <div><span>+978</span><span>members joined</span></div>
          <div><span>+50</span><span>fitness Programs</span></div>
          <div className="calories-pro">
            <img src={calories} alt="" className='calories' />
            <div>Calories Burned</div><div>220 kcal</div>
          </div>
        </div>
        <br />
        <div className='hero-button'>
          <button className='btn'>Get Started</button>
          <button className='btn'>Learn More</button>
        </div>

      </div>
      <div className="right-h">
        <button className='right-btn1'>Join Now</button>
        <div className='Heart-div'>
          <img src={Heart} alt="" className='Heart' />
          <div>Heart Rate</div>
          <div>116 bpm</div>
        </div>

      </div>
    </div>
  )
}

export default Header