import React from 'react';
import Header from './Header';
import Heading from './Heading';
import App from './App';

import './Hero.css';
const Hero = () => {
  return (
    <div className="Hero">
        <Header/>
        <Heading/>
        <App />
    </div>
    
  )
}

export default Hero